var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      key: _vm.containerKey,
      staticClass: "submenu",
      attrs: { id: "destinations" }
    },
    [
      _c("ul", { staticClass: " row align-top align-justify" }, [
        _c(
          "li",
          { staticClass: "destinations-col", attrs: { id: "regions-col" } },
          _vm._l(_vm.destinations, function(d, i) {
            return _c(
              "div",
              {
                key: d[0],
                staticClass: "submenu__headline first-menu-select",
                class: { selected: _vm.currentSector[0] == d[0] }
              },
              [
                _c(
                  "a",
                  {
                    class: { active: _vm.currentSector[0] == d[0] },
                    on: {
                      click: function($event) {
                        return _vm.setCurrentSector(d, i)
                      }
                    }
                  },
                  [_vm._v(_vm._s(d[0]))]
                )
              ]
            )
          }),
          0
        ),
        _vm._v(" "),
        _c(
          "li",
          { staticClass: "destinations-col", attrs: { id: "countries-col" } },
          [
            _c(
              "div",
              {
                staticClass: "submenu__headline",
                attrs: { id: "sectorHeader" }
              },
              [_c("span", [_vm._v(_vm._s(_vm.currentSector[0]))])]
            ),
            _vm._v(" "),
            _c(
              "ul",
              { staticClass: "destinations-submenu" },
              _vm._l(_vm.currentSector[1], function(c, i) {
                return _c("li", { key: c[1], staticClass: "submenu__link" }, [
                  _c(
                    "a",
                    {
                      on: {
                        click: function($event) {
                          return _vm.setCurrentCountry(c)
                        }
                      }
                    },
                    [_vm._v(_vm._s(c[0]))]
                  )
                ])
              }),
              0
            )
          ]
        ),
        _vm._v(" "),
        _c(
          "li",
          { staticClass: "destinations-col", attrs: { id: "countryHeader" } },
          [
            _vm.currentCountry.length
              ? [
                  _c("div", { staticClass: "submenu__headline" }, [
                    _c("span", [_vm._v(_vm._s(_vm.currentCountry[0]))])
                  ]),
                  _vm._v(" "),
                  _c(
                    "ul",
                    { staticClass: "destinations-submenu" },
                    [
                      _vm._l(_vm.currentCountry[2], function(r) {
                        return _c(
                          "li",
                          { key: r, staticClass: "submenu__link" },
                          [
                            _c(
                              "a",
                              {
                                attrs: {
                                  href:
                                    "/de/destination/" +
                                    _vm.currentCountry[0] +
                                    "/" +
                                    r
                                }
                              },
                              [_vm._v(_vm._s(r))]
                            )
                          ]
                        )
                      }),
                      _vm._v(" "),
                      _c("li", { staticClass: "submenu__link" }, [
                        _c(
                          "a",
                          {
                            staticClass: "cta cta--small cta--inverse",
                            attrs: {
                              href: "/de/destination/" + _vm.currentCountry[1]
                            }
                          },
                          [
                            _vm._v(
                              "Alle Angebote in " +
                                _vm._s(_vm.currentCountry[0]) +
                                " "
                            )
                          ]
                        )
                      ])
                    ],
                    2
                  )
                ]
              : _vm._e()
          ],
          2
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }